<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-calendar-text</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Worklogs</v-toolbar-title>

      <v-spacer />

      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        clearable
        single-line
        hide-details
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs align-with-title hide-slider>
          <v-tab @click="prev"><v-icon> mdi-chevron-left </v-icon></v-tab>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-tab style="width: 200px" v-bind="attrs" v-on="on">{{
                calendarTitle()
              }}</v-tab>
            </template>
            <v-date-picker
              :value="focus.substr(0, 7)"
              type="month"
              no-title
              scrollable
              @input="monthSelected"
              :first-day-of-week="1"
              color="primary"
            >
            </v-date-picker>
          </v-menu>
          <v-tab @click="next"><v-icon> mdi-chevron-right </v-icon></v-tab>
          <v-tab @click="setToday">Heute</v-tab>
        </v-tabs>
        <v-btn
          @click="addEvent"
          absolute
          bottom
          right
          color="success"
          fab
          small
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-card v-if="!search">
      <v-progress-linear
        indeterminate
        color="error"
        v-if="loadingEvents"
      ></v-progress-linear>
      <v-calendar
        ref="calendar"
        v-model="focus"
        :events="worklogs"
        event-start="date"
        event-name="author"
        type="month"
        color="primary"
        locale="de-CH"
        :loading="loadingEvents"
        :event-more="false"
        :event-color="eventBgColor"
        :event-text-color="eventTextColor"
        @click:event="showEvent"
        @click:date="editEvent"
        @change="getData"
        :weekdays="'1,2,3,4,5,6,0'"
      ></v-calendar>
    </v-card>
    <v-card v-else class="mt-2">
      <v-data-table
        :loading="loadingSearch"
        :headers="headers"
        :items="worklogSearch"
        :items-per-page="15"
        :item-class="() => 'clickable'"
        @click:row="(item) => showWorklog(item.id)"
      >
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:item.author="{ item }">
          <v-chip
            label
            :color="authorColor(item.author)[0]"
            :text-color="authorColor(item.author)[1]"
          >
            {{ item.author }}
          </v-chip>
        </template>
        <template v-slot:item.extract="{ item }">
          <div v-html="item.extract"></div>
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  name: "Worklogs",
  data: () => ({
    search: "",
    focus: "",
    worklogs: [],
    worklogSearch: [],
    loadingEvents: false,
    loadingSearch: false,
    menu: false,
    date: "",
    headers: [
      { text: "id", value: "id" },
      { text: "Datum", value: "date" },
      { text: "Autor", value: "author" },
      { text: "Inhalt", value: "extract" },
    ],
  }),
  watch: {
    search(newVal, oldVal) {
      if (!newVal) {
        return;
      }
      if (newVal !== oldVal && newVal.length > 3) {
        this.getDataSearch();
      }
    },
  },
  methods: {
    calendarTitle() {
      if (this.search) {
        return "Suche";
      }
      if (this.$refs.calendar) {
        return this.$refs.calendar.title;
      }
      return "";
    },
    async getData() {
      this.loadingEvents = true;
      const split = this.focus.split("-");
      const start = split[0] + "-" + split[1] + "-01";
      const end = split[0] + "-" + split[1] + "-31";

      this.worklogs = await this.apiList({
        resource: "worklog/worklog",
        query: "start=" + start + "&end=" + end,
      });

      this.loadingEvents = false;
    },
    async getDataSearch() {
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.loadingSearch = true;
        this.worklogSearch = await this.apiList({
          resource: "worklog/worklog",
          query: "search=" + this.search,
        });
        this.loadingSearch = false;
      }, 500);
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("de-CH", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    monthSelected(month) {
      this.focus = month + "-01";
    },
    showWorklog(id) {
      this.$router.push({
        name: "WorklogDetail",
        params: { id: id },
      });
    },
    showEvent(event) {
      this.showWorklog(event.event.id);
    },
    addEvent() {
      const today = new Date();
      const date = {
        date: today.toISOString().split("T")[0],
      };
      this.editEvent(date);
    },
    async editEvent(date) {
      //find worklog from this date
      const username = await this.$_profilePerson.code;
      const worklog = this.worklogs.find(
        (worklog) =>
          worklog.author === username && worklog.date === "" + date.date
      );
      if (worklog) {
        this.$router.push({
          name: "WorklogEdit",
          params: { id: worklog.id },
        });
      } else {
        this.$router.push({
          name: "WorklogEdit",
          params: { id: 0, date: date.date },
        });
      }
    },
    setToday() {
      if (this.search) {
        this.search = "";
      }
      const date = new Date();
      this.focus = date.toISOString().split("T")[0];
    },
    prev() {
      if (this.search) {
        this.search = "";
      } else {
        this.$refs.calendar.prev();
      }
    },
    next() {
      if (this.search) {
        this.search = "";
      } else {
        this.$refs.calendar.next();
      }
    },
    eventBgColor(event) {
      return event.person
        ? this.authorColor(event.person.code)[0]
        : this.authorColor(event.author)[0];
    },
    eventTextColor(event) {
      return event.person
        ? this.authorColor(event.person.code)[1]
        : this.authorColor(event.author)[1];
    },
    authorColor(author) {
      if (author === "jat") {
        return ["primary", "white"];
      }
      if (author === "fts") {
        return ["error", "black"];
      }
      if (author === "mer") {
        return ["secondary", "white"];
      }
      if (author === "grp") {
        return ["success", "black"];
      }
      if (author === "tho") {
        return ["warning", "black"];
      }
      return ["grey", "white"];
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "Worklogs") {
      this.getData();
    }
    next();
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.setToday();
    this.getData();
  },
};
</script>
